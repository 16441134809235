import React from "react";
import { graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import "../components/layout/custom-css/home.css";

const IndexPage = ({ data }) => (
  <Layout>
    <div className="container">
      <div id="home-section" className="my-3">
        <img src="/logo.png" alt="Szelence Café" className="img-fluid logo" />
      </div>

      <div className="container content-wrap">
        <div className="row my-5">
          <div className="col">
            <h1 className="text-center marketing">
              Családias. Egészséges. Ínyenc.
            </h1>
          </div>
        </div>

        <div className="row my-3">
          <div className="col-12 col-md px-3">
            <Link to="/eteleink">
              <GatsbyImage
                image={getImage(data.minicover1)}
                alt="Házi péksütik"
                className="rounded img-fluid"
              />
            </Link>
            <h3 className="my-3 text-center">
              <Link to="/eteleink" className="property-text">
                Házi péksütik
              </Link>
            </h3>
            <p className="text-justify">
              Kakaós, fahéjas csigák, túrós bukta, gyümolcsös kosarak, krumplis
              pogácsa, házi pite — péksüteményeink nem csak nálunk sülnek, hanem
              a mi Marcsi nénink gyúrja, dagasztja, formázza, szeretgeti őket a
              Szelence konyhájában. Ezen kívül bagellel, gluténmentes
              granolával, és vegán turmixszal is várunk, ha hétköznap
              délelőttönként éhesen térsz be hozzánk.
            </p>
          </div>
          <div className="col-12 col-md px-3">
            <Link to="/eteleink">
              <GatsbyImage
                image={getImage(data.minicover2)}
                alt="Vegetáriánus ebéd"
                className="rounded img-fluid"
              />
            </Link>
            <h3 className="my-3 text-center">
              <Link to="/eteleink" className="property-text">
                Vegetáriánus ebéd
              </Link>
            </h3>
            <p className="text-justify">
              Hétfőtől péntekig háromféle ebédmenüt kínálunk – glutén- és
              laktózmentes opciókkal, vegán fogásokkal. Egyedülálló
              változatosság, kreativitás, az ízek és alapanyagok páratlan
              gazdagsága jellemzi ételeinket.
            </p>
          </div>
          <div className="col-12 col-md px-3">
            <Link to="/eteleink">
              <GatsbyImage
                image={getImage(data.minicover3)}
                alt="Vegán süti, kávé, tea"
                className="rounded img-fluid"
              />
            </Link>
            <h3 className="my-3 text-center">
              <Link to="/tortarendeles" className="property-text">
                Vegán süti, kávé, tea
              </Link>
            </h3>
            <p className="text-justify">
              A nap bármely szakában betérhetsz hozzánk egy szelet nyers vegán
              sütire (tej-, tojás-, glutén-, és cukormentes), egy illatos chai
              lattéra, vagy egy szezonális frissítőre! Kávéinkat Sanremo Treviso
              kávégéppel készítjük, és kérésedre alternatív tejjel (rizs, zab)
              szolgáljuk fel.
            </p>
          </div>
        </div>

        <div className="row my-3">
          <div className="col-12 col-md px-3">
            <Link to="/eteleink">
              <GatsbyImage
                image={getImage(data.minicover4)}
                alt="Szombati reggeli"
                className="rounded img-fluid"
              />
            </Link>
            <h3 className="my-3 text-center">
              <Link to="/tortarendeles" className="property-text">
                Szombati reggeli
              </Link>
            </h3>
            <p className="text-justify">
              Szombatonként gazdag reggeli (tízórai, brunch, már-már ebéd)
              kínálattal várunk: ínycsiklandó bagelek, wrapok és vegán tapas
              tálak valamint hagyományos és mentes tortáink közül válogathatsz.
              (Ne feledd, nyári szombatokon zárva vagyunk!)
            </p>
          </div>
          <div className="col-12 col-md px-3">
            <Link to="/tortarendeles">
              <GatsbyImage
                image={getImage(data.minicover5)}
                alt="„Mindenmentes” torták"
                className="rounded img-fluid"
              />
            </Link>
            <h3 className="my-3 text-center">
              <Link to="/tortarendeles" className="property-text">
                „Mindenmentes” torták
              </Link>
            </h3>
            <p className="text-justify">
              Messzeföldön híres nyers vegán tortáinkból bármikor csemegézhetsz
              nálunk, vagy rendelhetsz egész tortát, hogy megkoronázz vele egy
              fontos eseményt.
            </p>
          </div>
          <div className="col-12 col-md px-3">
            <Link to="/programok">
              <GatsbyImage
                image={getImage(data.minicover6)}
                alt="Programok"
                className="rounded img-fluid"
              />
            </Link>
            <h3 className="my-3 text-center">
              <Link to="/programok" className="property-text">
                Programok
              </Link>
            </h3>
            <p className="text-justify">
              Kézműves és gasztro workshopok, villásreggeli, mini fesztiválok –
              kicsiknek és nagyoknak is ajánljuk rendszeres programjainkat.
            </p>
          </div>
        </div>

        <div className="row my-3">
          <div className="col-12 col-md px-3">
            <Link to="/teremberles">
              <GatsbyImage
                image={getImage(data.minicover7)}
                alt="Terembérlés"
                className="rounded img-fluid"
              />
            </Link>
            <h3 className="my-3 text-center">
              <Link to="/teremberles" className="property-text">
                Terembérlés
              </Link>
            </h3>
            <p className="text-justify">
              Hangulatos helyszínt keresel? Családi eseményre, céges
              összejövetelre, általad szervezett programokra is ajánljuk vintage
              hangulatú, otthonos tereinket, profi kapcsolódó
              szolgáltatásainkat.
            </p>
          </div>
          <div className="col-12 col-md px-3">
            <Link to="/teremberles">
              <GatsbyImage
                image={getImage(data.minicover8)}
                alt="Szülinapok"
                className="rounded img-fluid"
              />
            </Link>
            <h3 className="my-3 text-center">
              <Link to="/teremberles" className="property-text">
                Szülinapok
              </Link>
            </h3>
            <p className="text-justify">
              Bérelj tőlünk termet szülinapi rendezvényre, gyermekek és
              felnőttek részére.
            </p>
          </div>
          <div className="col-12 col-md px-3">
            <Link to="/eskuvo">
              <GatsbyImage
                image={getImage(data.minicover9)}
                alt="Esküvő"
                className="rounded img-fluid"
              />
            </Link>
            <h3 className="my-3 text-center">
              <Link to="/eskuvo" className="property-text">
                Esküvő
              </Link>
            </h3>
            <p className="text-justify">
              Egyedi, tudatos, meghitt. Kis létszámú (max.25 fő) esküvőkhöz
              tökéletes helyszín a Szelence, Buda szívében.
            </p>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export const pageQuery = graphql`
  query {
    brunchbox: file(relativePath: { eq: "brunchbox.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, quality: 100)
      }
    }
    szelence_szulinap: file(
      relativePath: { eq: "index/szelence_szulinap.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    minicover1: file(relativePath: { eq: "index/Szelence_2019_0911441.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    minicover2: file(relativePath: { eq: "index/szelence2-mosaic.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    minicover3: file(relativePath: { eq: "index/Szelence_2019_0911483.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    minicover4: file(relativePath: { eq: "index/Szelence_2019_0911490.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    minicover5: file(relativePath: { eq: "index/szelence5-mosaic.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    minicover6: file(relativePath: { eq: "index/szelence6-mosaic.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    minicover7: file(relativePath: { eq: "index/szelence7-mosaic.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    minicover8: file(relativePath: { eq: "index/szelence8-mosaic.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    minicover9: file(relativePath: { eq: "index/szelence9-mosaic.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`;

export const Head = () => <title>Szelence Café</title>;

export default IndexPage;
